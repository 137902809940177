import { useRemixForm } from "remix-hook-form";
import type * as yup from "yup";
import { TwoColumns } from "~/layout/TwoColumns";
import { EmailForm } from "~/pages/LoginPage/components/EmailForm";
import { Footer } from "~/pages/LoginPage/components/Footer";
import { Header } from "~/pages/LoginPage/components/Header/Header";
import { Logo } from "~/pages/LoginPage/components/Header/Logo";
import { usePostForm } from "~/pages/usePostForm";
import { resolver } from ".";
import { Item } from "~/pages/LoginPage/components";
import { useSearchParams } from "@remix-run/react";
import { useEffect } from "react";
import type { LoginContentVariant } from "./LoginContentVariant";
import type { emailFormSchema } from "./emailFormSchema";

export type LoginFormValues = yup.InferType<typeof emailFormSchema>;

const LeftContent = ({ variant }: { variant: LoginContentVariant }) => {
  if (variant === "signup-api") {
    return (
      <div className="max-w-md m-auto">
        <h2 className="text-4xl font-semibold mb-8">
          Une API pour les données Linky
        </h2>
        <div className="py-4 flex flex-col leading-tight">
          <Item verticallyCenteredChip n={1} lightTicks>
            Inscrivez-vous
          </Item>
          <Item verticallyCenteredChip n={2} lightTicks>
            Nous récoltons les consentements nécessaires
          </Item>
          <Item verticallyCenteredChip last n={3} lightTicks>
            Accédez aux courbes de consommation
          </Item>
        </div>
      </div>
    );
  } else if (variant === "login-api") {
    return (
      <div className="max-w-md m-auto">
        <h2 className="text-4xl font-semibold mb-8">Vous revoilà</h2>
        <div className="py-4 flex flex-col leading-tight">
          <Item verticallyCenteredChip n={1} lightTicks>
            Administrez votre accès à l'API
          </Item>
          <Item verticallyCenteredChip n={1} lightTicks>
            Consultez la documentation
          </Item>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-md m-auto">
      <h2 className="text-4xl font-semibold mb-8">Bienvenue 👋</h2>
      {variant === "login-effacement" ? (
        <div className="py-4 flex flex-col leading-tight">
          <Item verticallyCenteredChip n={1} lightTicks>
            Réduisez votre empreinte carbone
          </Item>
          <Item verticallyCenteredChip n={2} lightTicks>
            Soyez rémunérés pour votre comportement vertueux
          </Item>
          <Item verticallyCenteredChip last n={3} lightTicks>
            Accélérez la transition écologique
          </Item>
        </div>
      ) : (
        <div className="py-4 flex flex-col leading-tight">
          <Item verticallyCenteredChip n={1} lightTicks>
            Connectez vos équipements
          </Item>
          <Item verticallyCenteredChip n={2} lightTicks>
            Associez votre compteur Linky
          </Item>
          <Item verticallyCenteredChip last n={3} lightTicks>
            Réduisez votre empreinte carbone
          </Item>
        </div>
      )}
    </div>
  );
};

export const LoginSignUpPage = ({
  variant,
  prefillEmail,
}: {
  variant: LoginContentVariant;
  prefillEmail?: string | undefined;
}) => {
  const { control, register, handleSubmit } = useRemixForm<LoginFormValues>({
    resolver,
    defaultValues: {
      email: prefillEmail || "",
      mainApplication: variant.includes("effacement") ? "demandReponse" : "api",
    },
  });

  const PostForm = usePostForm(handleSubmit);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    searchParams.delete("prefillEmail");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <>
      <Logo className="max-md:hidden fixed top-4 left-4 lg:top-8 lg:left-8 z-10 w-16 h-16 lg:w-24 lg:h-24 transition-all" />
      <Header title="" className="md:hidden" />
      <TwoColumns
        verticallyCentered
        as={PostForm}
        leftBlue={
          <div className="px-8 lg:pt-16 h-full flex flex-col justify-center items-center">
            <LeftContent variant={variant} />
          </div>
        }
        col1={<LeftContent variant={variant} />}
        col2={
          <EmailForm
            variant={variant}
            control={control}
            register={register}
            className="max-w-md m-auto"
          />
        }
      />
      <Footer />
    </>
  );
};
