import * as React from "react";
import type { SVGProps } from "react";
const SvgX = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={17}
    fill="none"
    viewBox="0 0 17 17"
    {...props}
  >
    <path
      fill="#BE123C"
      d="M4.655 4.975a.5.5 0 0 1 .708 0l2.646 2.647 2.646-2.647a.5.5 0 0 1 .708.708L8.716 8.329l2.647 2.646a.5.5 0 1 1-.708.708L8.009 9.036l-2.646 2.647a.5.5 0 1 1-.708-.708l2.647-2.646-2.647-2.646a.5.5 0 0 1 0-.708"
    />
  </svg>
);
export default SvgX;
