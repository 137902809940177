import * as React from "react";
import type { SVGProps } from "react";
const SvgCheckLg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M12.736 3.97a.73.73 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 .24-1.223.73.73 0 0 1 .807.163l3.052 3.093 5.4-6.425z" />
  </svg>
);
export default SvgCheckLg;
