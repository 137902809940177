import React from "react";
import { TickCircle, X } from "~/components/icons";
import SvgCheckLg from "~/components/icons/CheckLg";

export const Item = ({
  n,
  children,
  last,
  separator,
  red,
  lightTicks,
  verticallyCenteredChip,
}: React.PropsWithChildren<{
  n: number;
  last?: true;
  separator?: true;
  red?: true;
  lightTicks?: true;
  verticallyCenteredChip?: true;
}>) => {
  const colors = lightTicks
    ? ""
    : red
    ? "bg-red-100 border-red-50"
    : "bg-green-200 border-green-50";
  return (
    <div className="flex flex-col justify-center gap-2">
      <div className="mt-[5px] mb-[24px] justify-start gap-2 inline-flex">
        <div
          className={`flex-col items-center gap-[5px] inline-flex ${
            verticallyCenteredChip ? `justify-center` : `justify-start`
          }`}
        >
          {lightTicks ? (
            <TickCircle />
          ) : (
            <div className="justify-start items-start inline-flex">
              <div className="w-[26px] h-[26px] justify-start items-start inline-flex">
                <div
                  className={`w-[26px] h-[26px] ${colors} rounded-[999px] border-4 justify-center items-center flex`}
                >
                  <div className="w-4 h-4 relative">
                    {red ? <X /> : <SvgCheckLg className={"fill-green-900"} />}
                  </div>
                </div>
              </div>
            </div>
          )}
          {last || !separator || (
            <div className="w-px grow shrink basis-0 bg-gray-200 first:hidden" />
          )}
        </div>
        <div
          className={`flex-col items-start inline-flex ${
            verticallyCenteredChip ? `justify-center` : `justify-start`
          }`}
        >
          <div className=" text-gray-800 text-base font-normal">{children}</div>
        </div>
      </div>
    </div>
  );
};
