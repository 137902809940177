import { useNavigate, useNavigation } from "@remix-run/react";
import type { Control } from "react-hook-form";
import { useWatch } from "react-hook-form";
import type { useRemixForm } from "remix-hook-form";
import { twMerge } from "tailwind-merge";
import { Button } from "~/components/Button";
import type { LoginFormValues } from "~/routes/_application+/login_+";
import type { LoginContentVariant } from "~/routes/_application+/login_+/LoginContentVariant";

export const EmailForm = ({
  register,
  control,
  className,
  variant,
}: {
  register: ReturnType<typeof useRemixForm<LoginFormValues>>["register"];
  control: Control<LoginFormValues>;
  className?: string;
  variant: LoginContentVariant;
}) => {
  const emailW = useWatch({
    name: "email",
    control,
  });

  const navigation = useNavigation();

  const navigate = useNavigate();

  return (
    <div className={twMerge("flex flex-col items-center", className)}>
      <label htmlFor="email" className="text-md mt-4 mb-2 self-start">
        Email
      </label>
      <input
        {...register("email", { required: true })}
        className="py-5 px-5 block w-full border-solid border-gray-400 border-[1px] rounded-md"
        placeholder="Email"
        data-testid="email-input"
        type="email"
        autoComplete="email"
      />
      <Button
        className="w-full mt-4"
        type="submit"
        disabled={!emailW || emailW.length === 0}
        data-testid="submit-button"
        loading={navigation.state !== "idle"}
      >
        {variant.startsWith("signup") ? `M‘inscrire` : `Me connecter`}
      </Button>
      <div className="flex max-md:flex-col items-center gap-2 mt-4">
        <span>
          {variant.startsWith("signup")
            ? `Déjà un compte ?`
            : `Pas encore de compte ?`}
        </span>
        <button
          className="rounded-full bg-blue-500 text-white text-xs border-gray-200 px-1.5 py-0.5"
          onClick={() => {
            return navigate(getSignupIfLogin(variant));
          }}
        >
          {variant.startsWith("signup") ? `Connectez-vous` : `Inscrivez-vous`}
        </button>
      </div>
    </div>
  );
};

const getSignupIfLogin = (variant: LoginContentVariant) => {
  switch (variant) {
    case "login-api":
      return "/signup";
    case "login-effacement":
      return "/effacement/signup";
    case "signup-api":
      return "/login";
    case "signup-effacement":
      return "/effacement/login";
  }
};
