import * as yup from "yup";

type UserSignupFlow = "demandReponse" | "api";

export const emailFormSchema = yup.object().shape({
  email: yup.string().email().required(),
  mainApplication: yup
    .mixed<UserSignupFlow>()
    .oneOf(["demandReponse", "api"] as const),
});
